import React, { useEffect, useState } from "react";
import {
  Select,
  MenuItem,
  InputLabel,
  FormControl,
  Box,
  TextField,
  Button,
  Grid,
  tableCellClasses,
  styled,
  TablePagination,
  CircularProgress,
} from "@mui/material";
import {
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
} from "@mui/material";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import { EMSDashboardData } from "../../api/EMS API/getdashboarddata";
import { format } from "date-fns";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { parseISO } from "date-fns";
import DownloadButton from "../../utils/DownloadButton";
import DownloadReport from "../../utils/DownloadReport";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#1FAEC5",
    color: theme.palette.common.white,
    position: "sticky",
    top: 0,
    zIndex: 1,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

const getCurrentDate = () => {
  const today = new Date();
  const yyyy = today.getFullYear();
  const mm = String(today.getMonth() + 1).padStart(2, "0");
  const dd = String(today.getDate()).padStart(2, "0");
  return `${yyyy}-${mm}-${dd}`;
};

export default function RawData() {
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [machineData, setMachineData] = useState([]);
  const [lineData, setLineData] = useState([]);
  const [refreshData, setRefreshData] = useState(false);
  const [error, setError] = useState(null);
  const [severity, setSeverity] = useState("success");
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [rawData, setRawData] = useState({
    deviceNo: "",
    startDate: getCurrentDate(),
    endDate: getCurrentDate(),
  });
  const [data, setData] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const [selectedLine, setSelectedLine] = useState("");
  const [loading, setLoading] = useState(false);

  const handleSnackbarOpen = (message, severity) => {
    setSnackbarMessage(message);
    setSeverity(severity);
    setOpenSnackbar(true);
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setRawData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
    if (name === "lineNo") {
      setSelectedLine(value);
    }
  };
  const handleRawData=()=>{
    const formatDate = (date) => {
      const options = { day: "2-digit", month: "short", year: "numeric" };
      return new Date(date)
        .toLocaleDateString("en-GB", options)
        .replace(/ /g, "-");
    };
    const apiCall = async () => {
      const response = await EMSDashboardData.getEMSRawData({
        deviceNo: rawData.deviceNo,
        startDate: formatDate(rawData.startDate),
        endDate: formatDate(rawData.endDate),
      });
      console.log("API response in handleRawData:", response);
      return response;
    };
    const formatData=(data)=>{
      const dataArray=Array.isArray(data)?data:[data]
      return dataArray.map((row)=>({
        "Date Time": row.dateTime != null ? row.dateTime : "",
        "Voltage R phase(V)": row.voltageR != null ? row.voltageR : "",
        "Voltage Y phase(V)": row.voltageY !== null ? row.voltageY : "",
        "Voltage B phase(V)": row.voltageB !== null ? row.voltageB : "",
        "Current R phase(A)": row.currentR !== null ? row.currentR : "",
        "Current Y phase(A)": row.currentY !== null ? row.currentY : "",
        "Current B phase(A)": row.currentB !== null ? row.currentB : "",
        "Power Factor": row.powerFactor !== null ? row.powerFactor : "",
        "Active Power(W)": row.activePower !== null ? row.activePower : "",
        "Reactive Power(KVAr)":
          row.reactivePower !== null ? row.reactivePower : "",
        "R-phase voltage THD in %":
          row.voltageThdR !== null ? row.voltageThdR : "",
        "Y-phase voltage THD in %": row.voltageThdY !== null ? row.voltageThdY : "",
        "B-phase voltage THD in %": row.voltageThdB !== null ? row.voltageThdB : "",
        "R-phase current THD in %": row.currentThdR !== null ? row.currentThdR : "",
        "Y-phase current THD in %": row.currentThdY !== null ? row.currentThdY : "",
        "B-phase current THD in %": row.currentThdB !== null ? row.currentThdB : "",
        "Active Energy(kWh)": row.activeEnergy !== null ? row.activeEnergy : "",
        "Reactive Lag(VArh)":
          row.reactiveLag !== null ? row.reactiveLag : "",
          "Reactive Lead(kVArh)": row.reactiveLead !== null ? row.reactiveLead : "",
          "Import(kWh)": row.importEnergy !== null ? row.importEnergy : "",
          "Export(kWh)": row.exportEnergy !== null ? row.exportEnergy : "",
          "Demand Active Power(W)": row.demandActivePower !== null ? row.demandActivePower : "",

      }))
    }
    return { apiCall, formatData, fileName: "RawData.xlsx" };
  }
  const handleAddSubmit = async (event) => {
    event.preventDefault();
    setLoading(true);
    try {
      const formatDate = (date) => {
        const options = { day: "2-digit", month: "short", year: "numeric" };
        return new Date(date)
          .toLocaleDateString("en-GB", options)
          .replace(/ /g, "-");
      };
  
      const response = await EMSDashboardData.getEMSRawData({
        deviceNo: rawData.deviceNo,
        startDate: formatDate(rawData.startDate),
        endDate: formatDate(rawData.endDate),
      });
  
     
      console.log("API response:", response);
  
    
      if (response && response.data) {
        const responseData = Array.isArray(response.data) ? response.data : [response.data];
        setData(responseData); // Ensure we always work with an array
        handleSnackbarOpen("Raw Data fetched successfully!", "success");
      } else {
        handleSnackbarOpen("No data available from the response", "info");
      }
    } catch (error) {
      console.error("Error fetching EMS dashboard data:", error);
      handleSnackbarOpen("Error fetching raw data. Please try again.", "error");
    } finally {
      setLoading(false);
    }
  };
  
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const emptyRows =
    rowsPerPage - Math.min(rowsPerPage, data.length - page * rowsPerPage);

  return (
    <div style={{ padding: "0px 20px", width: "100%" }}>
      <div
        style={{
          display: "flex",
          justifyContent: "flex-start",
          margin: "20px 0px 20px 0px",
        }}
      >
        <h2>Raw Data</h2>
      </div>
      <Grid
        container
        spacing={2}
        style={{ width: "100%", alignItems: "center", marginBottom: "10px" }}
      >
        <Grid item xs={6} sm={3}>
          <FormControl sx={{ minWidth: 250 }}>
            <InputLabel>Select Device</InputLabel>
            <Select
              name="deviceNo"
              value={rawData.deviceNo}
              onChange={handleInputChange}
            >
              <MenuItem value="2">Energy Meter</MenuItem>
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={6} sm={3}>
          <FormControl sx={{ minWidth: 250 }}>
            <TextField
              label="Start Date"
              name="startDate"
              type="date"
              value={rawData.startDate}
              onChange={handleInputChange}
            />
          </FormControl>
        </Grid>
        <Grid item xs={6} sm={3}>
          <FormControl sx={{ minWidth: 250 }}>
            <TextField
              label="End Date"
              name="endDate"
              type="date"
              value={rawData.endDate}
              onChange={handleInputChange}
            />
          </FormControl>
        </Grid>
        <Grid item>
          <Button variant="contained" color="primary" onClick={handleAddSubmit}>
            OK
          </Button>
        </Grid>
      </Grid>
      <DownloadReport {...handleRawData()} />
        
      <Box sx={{ marginTop: "20px", maxHeight: "500px", overflow: "auto" }}>
        {loading ? (
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "50vh",
            }}
          >
            <CircularProgress />
          </Box>
        ) : (
          <Table style={{ boxShadow: "0px 0px 5px rgba(0, 0, 0, 0.3)" }}>
            <TableHead>
           <TableRow>
                <StyledTableCell  className="table-cell">Date Time </StyledTableCell >
                <StyledTableCell  className="table-cell">Voltage R phase(V) </StyledTableCell >
                <StyledTableCell  className="table-cell">Voltage Y phase(V)</StyledTableCell >
                <StyledTableCell  className="table-cell">Voltage B phase(V)</StyledTableCell >
                
                <StyledTableCell  className="table-cell">Current R phase(A)</StyledTableCell >
                
                <StyledTableCell  className="table-cell">Current Y phase(A)</StyledTableCell >
                
                <StyledTableCell  className="table-cell">Current B phase(A)</StyledTableCell >
                <StyledTableCell  className="table-cell"> Power Factor</StyledTableCell >

                <StyledTableCell  className="table-cell">Active Power(W) </StyledTableCell >
                <StyledTableCell  className="table-cell">Reactive Power(KVAr)</StyledTableCell >
                <StyledTableCell  className="table-cell">R-phase voltage THD in %</StyledTableCell >
                <StyledTableCell  className="table-cell">Y-phase voltage THD in %</StyledTableCell >
                <StyledTableCell  className="table-cell">B-phase voltage THD in %</StyledTableCell >
                <StyledTableCell  className="table-cell">R-phase current THD in %</StyledTableCell >
                <StyledTableCell  className="table-cell">Y-phase current THD in %</StyledTableCell >
                <StyledTableCell  className="table-cell">B-phase current THD in %</StyledTableCell >
                <StyledTableCell  className="table-cell">Active Energy(kWh)</StyledTableCell >
                <StyledTableCell  className="table-cell"> Reactive Lag(VArh)</StyledTableCell > 
                <StyledTableCell className="table-cell">Reactive Lead(kVArh)</StyledTableCell>
                 <StyledTableCell  className="table-cell">  Import(kWh)</StyledTableCell > 
                  <StyledTableCell  className="table-cell"> Export(kWh)</StyledTableCell > 
             <StyledTableCell  className="table-cell">Demand Active Power(W)</StyledTableCell > 
                  </TableRow>
              </TableHead>
            <TableBody>
              {data && data.length > 0 ? (
                data
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((row, index) => (
                    <StyledTableRow key={index}>
                      <StyledTableCell>{row.dateTime}</StyledTableCell>
                      <StyledTableCell>{row.voltageR}</StyledTableCell>
                      <StyledTableCell>{row.voltageY}</StyledTableCell>
                      <StyledTableCell>{row.voltageB}</StyledTableCell>
                      <StyledTableCell>{row.currentR}</StyledTableCell>
                      <StyledTableCell>{row.currentY}</StyledTableCell>
                      <StyledTableCell>{row.currentB}</StyledTableCell>
                      <StyledTableCell>{row.powerFactor}</StyledTableCell>

                      <StyledTableCell>{row.activePower}</StyledTableCell>
                      <StyledTableCell>{row.reactivePower}</StyledTableCell>
                      <StyledTableCell>{row.voltageThdR}</StyledTableCell>
                      <StyledTableCell>{row.voltageThdY}</StyledTableCell>
                      <StyledTableCell>{row.voltageThdB}</StyledTableCell>
                      <StyledTableCell>{row.currentThdR}</StyledTableCell>
                      <StyledTableCell>{row.currentThdY}</StyledTableCell>
                      <StyledTableCell>{row.currentThdB}</StyledTableCell>
                      <StyledTableCell>{row.activeEnergy}</StyledTableCell>
                      <StyledTableCell>{row.reactiveLag}</StyledTableCell>
                      <StyledTableCell>{row.reactiveLead}</StyledTableCell>
                      <StyledTableCell>{row.importEnergy}</StyledTableCell>
                      <StyledTableCell>{row.exportEnergy}</StyledTableCell>

                      <StyledTableCell>{row.demandActivePower}</StyledTableCell>

                    </StyledTableRow>
                  ))
              ) : (
                <StyledTableRow>
                  <StyledTableCell colSpan={22}>No data available</StyledTableCell>
                </StyledTableRow>
              )}
           
            </TableBody>
          </Table>
        )}
      
      </Box>
      <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={data.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />

      <Snackbar
        open={openSnackbar}
        autoHideDuration={6000}
        onClose={() => setOpenSnackbar(false)}
      >
        <MuiAlert
          onClose={() => setOpenSnackbar(false)}
          severity={severity}
          sx={{ width: "100%" }}
        >
          {snackbarMessage}
        </MuiAlert>
      </Snackbar>
    </div>
  );
}
