import React from 'react';
import { Line } from 'react-chartjs-2';
import { Chart as ChartJS, CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend } from 'chart.js';

ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend);

const LineChart = ({ data, labels, title, xAxisLabel, yAxisLabel, defaultColor = 'white' }) => {
  const chartData = {
    labels: labels,
    datasets: data.map((dataset, index) => ({
      label: dataset.name,
      data: dataset.data,
      fill: false,
      borderColor: getColor(dataset.name, index, defaultColor),
      color: "white",
      tension: 0.1
    }))
  };

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        position: 'top',
        labels: {
          color: 'white',
          font: {
            size: 12
          }
        }
      },
      title: {
        display: true,
        text: title,
        font: {
          size: 16
        },
        color: 'white'
      },
    },
    scales: {
      x: {
        title: {
          display: true,
          text: xAxisLabel,
          color: 'white',
        },
        grid: {
          color: 'rgba(255, 255, 255, 0.1)'
        },
        ticks: {
          color: 'white'
        }
      },
      y: {
        title: {
          display: true,
          text: yAxisLabel,
          color: 'white',
        },
        grid: {
          color: 'rgba(255, 255, 255, 0.1)'
        },
        ticks: {
          color: 'white'
        },
      }
    }
  };

  function getColor(name, index, defaultColor) {
    if (name === 'R') return 'red';
    if (name === 'Y') return 'yellow';
    if (name === 'B') return 'blue';
    if (name === 'Import') return '#efe4b0';
    if (name === 'Export') return '#b97a58';
    if (name === 'Loss') return '#ff6b6b';
    if (name === 'THD Current') return '#d4fefb';
    return defaultColor || `hsl(${index * 137.5},70%,50%)`;
  }

  return <Line data={chartData} options={options} />;
};

export default LineChart;