// import { Router } from '@mui/icons-material'
import React from "react";
import { HashRouter as Router, Route, Routes } from "react-router-dom";
import CBM from "../components/cbm";
import Operations from "../components/operations";
import Sidebar from "../components/sidebar";
import PartMaster from "../components/partmaster";
// import SignInSide from '../components/Login'
import Login from "../components/Login";
import PathrediOpe from "../components/pathrediope";
import GhaziabadOpe from "../components/ghaziabadope";
import UATRawData from "../components/UATRawData";
import UATReport from "../components/UATReport";
import UATTesting from "../components/UATTesting";
import FacilityManagement from "../components/facilitymanagement";
import Rawghaziabad from "../components/Rawghaziabad";
import Rawpathrediope from "../components/Rawpathrediope copy";
import DeviceMaster from "../components/devicemaster";
import MachineMaster from "../components/machinemaster";
import LineMaster from "../components/linemaster";
import PlantMaster from "../components/plantmaster";
import FrdcMaster from "../components/frdcmaster";
import Temp from "../components/temp";
import MachineDownTime from "../components/machinedowntime";
import QualityRejection from "../components/qualityrejection";
import ShiftMaster from "../components/shiftmaster";
import GhaziabadOpe2 from "../components/ghaziabadope2";
import PathrediOpe2 from "../components/pathrediope2";
import MachineInput from "../components/machineinput";
import ChangeOverMaster from "../components/changeovermaster";
import FacilitySustainability from "../components/facilitysustainability";
import HourlyBucketM1 from "../components/hourlybucket_m1";
import HourlyBucketM2 from "../components/hourlybucket_m2";

import EnergyManagement from "../components/energymanagement";
import BasicGauges from "../components/chartssize";
import AirQualityManagement from "../components/airqualitymanagement";
import DieselGenset from "../components/dieselgenset";
import STPManagement from "../components/stpmanagement";
import EnlargedChartPage from "../components/fullviewchart";
import WaterManagement from "../components/watermanagement";
import Welcome from "../components/welcome";
import DailyReportM1 from "../components/dailyreportm1";
import DailyReportM2 from "../components/dailyreportm2";
import WeeklyReportM1 from "../components/weeklyreportm1";
import WeeklyReportM2 from "../components/weeklyreportm2";
import MonthlyReportM1 from "../components/monthlyreportm1";
import MonthlyReportM2 from "../components/monthlyreportm2";
import QuaterlyReportM1 from "../components/quaterlyreportm1";
import QuaterlyReportM2 from "../components/quaterlyreportm2";
import YearlyReportM1 from "../components/yearlyreportm1";
import YearlyReportM2 from "../components/yearlyreportm2";
import UnknownLoss from "../components/unknownloss";
import COntrolRoom from "../components/controlroom";
import WholeDayReportM1 from "../components/wholedayreportm1";
import WholeDayReportM2 from "../components/wholedayreportm2";
import ChartComponent from "../components/charts";
import Dashboard from "../components/dashboard";
import Publisher from "../components/publishers";
import Tbm from "../components/tbm";
import Thresholds from "../components/thresholds";
import ADMReports from "../components/Reports";
import SmartDashboard from "../components/SmartDashboard";
import EMSDashboard from "../components/EMS/EMSDashboard";
import RawData from "../components/EMS/RawData";
import CBMDetail from "../components/Scoul/cbmDetails";
import IconicDashboard from "../components/Scoul/CockPitView";
import VibrationGraphs from "../components/Scoul/CBMGraphs";
import PowerGraphs from "../components/Scoul/EnergyGraphs";
import ADMRawData from "../components/ADMRawData";
export default function MyRoutes() {
  return (
    <Routes>
      <Route path="/cbm" element={<CBM />} />
      <Route path="/operations" element={<Operations />} />
      <Route path="/sidebar" element={<Sidebar />} />
      <Route path="/login" element={<Login />} />
      <Route path="" element={<Login />} />
      <Route path="/pathredi_ope" element={<PathrediOpe />} />
      <Route path="/uat/uat_rawdata" element={<UATRawData />} />
      <Route path="/uat/uat_reports" element={<UATReport />} />
      <Route path="/uat/uat_testing" element={<UATTesting />} />
      <Route path="/rawdata" element={<RawData />} />
      <Route path="/hour_bucket_m1" element={<HourlyBucketM1 />} />
      <Route path="/hour_bucket_m2" element={<HourlyBucketM2 />} />
      <Route path="/raw_data/uat_ghaziabad" element={<Rawghaziabad />} />
      <Route path="/ghaziabad_ope" element={<GhaziabadOpe />} />
      <Route path="/scoul/cbmdetail" element={<CBMDetail />} />
      <Route path="/facilitymanagement" element={<FacilityManagement />} />
      <Route
        path="/plant_architecture/plant_master"
        element={<PlantMaster />}
      />
      <Route path="/plant_architecture/linemaster" element={<LineMaster />} />
      <Route
        path="/plant_architecture/devicemaster"
        element={<DeviceMaster />}
      />
      <Route path="/publishers" element={<Publisher />} />
      <Route path="/tbm" element={<Tbm />} />
      <Route path="/admreports" element={<ADMReports />} />
      <Route path="/smart_dashboard" element={<SmartDashboard />} />
      <Route path="/scoul/cockpitview" element={<IconicDashboard />} />
      <Route path="/scoul/powergraphs" element={<PowerGraphs />} />
      <Route path="/scoul/cbmgraphs" element={<VibrationGraphs />} />
      <Route path="plant_architecture/thresholds" element={<Thresholds />} />
      <Route path="/plant_architecture/part_master" element={<PartMaster />} />
      <Route
        path="/production_plan/machine_downtime"
        element={<MachineDownTime />}
      />
      <Route
        path="/production_plan/quality_rejection"
        element={<QualityRejection />}
      />
      <Route path="/production_plan/unknown_loss" element={<UnknownLoss />} />
      <Route
        path="/device_status"
        element={<COntrolRoom />}
      />
      <Route path="/production_plan/shift_master" element={<ShiftMaster />} />
      <Route path="/dashboard" element={<Dashboard />} />
      <Route path="/method2/machine_input" element={<MachineInput />} />
      <Route path="/method2/ghaziabad_ope_2" element={<GhaziabadOpe2 />} />
      <Route path="/method2/pathredi_ope_2" element={<PathrediOpe2 />} />
      <Route path="/method1/pathredi_ope_1" element={<PathrediOpe />} />
      <Route path="/method1/ghaziabad_ope_1" element={<GhaziabadOpe />} />
      <Route
        path="/plant_architecture/frdc_master"
        element={<ChangeOverMaster />}
      />
      <Route
        path="/facilitysustainability"
        element={<FacilitySustainability />}
      />
      <Route path="/energymanagement" element={<EnergyManagement />} />
      <Route path="/airquality" element={<AirQualityManagement />} />
      <Route path="/dieselgenset" element={<DieselGenset />} />
      <Route path="/ems/emsdashboard" element={<EMSDashboard />} />
      <Route path="/ems/rawdata" element={<RawData />} />
      <Route path="/charts" element={<ChartComponent />} />
      <Route path="/temp" element={<Temp />} />
      <Route path="/basic" element={<BasicGauges />} />
      <Route path="/stpmanagement" element={<STPManagement />} />
      <Route path="/fullviewchart" element={<EnlargedChartPage />} />
      <Route path="/watermanagement" element={<WaterManagement />} />
      <Route path="/welcome" element={<Welcome />} />
      <Route path="/reportm1/daily" element={<DailyReportM1 />} />
      <Route path="/reportm1/hour_bucket_m1" element={<HourlyBucketM1 />} />
      <Route path="/reportm2/hour_bucket_m2" element={<HourlyBucketM2 />} />
      <Route path="/reportm2/daily" element={<DailyReportM2 />} />
      <Route path="/reportm1/wholedayreportm1" element={<WholeDayReportM1 />} />
      <Route path="/reportm2/wholedayreportm2" element={<WholeDayReportM2 />} />
      <Route path="/reportm1/weekly" element={<WeeklyReportM1 />} />
      <Route path="/reportm2/weekly" element={<WeeklyReportM2 />} />{" "}
      <Route path="/reportm1/monthly" element={<MonthlyReportM1 />} />
      <Route path="/reportm2/monthly" element={<MonthlyReportM2 />} />
      <Route path="/reportm1/quaterly" element={<QuaterlyReportM1 />} />
      <Route path="/reportm2/quaterly" element={<QuaterlyReportM2 />} />
      <Route path="/reportm1/yearly" element={<YearlyReportM1 />} />
      <Route path="/reportm2/yearly" element={<YearlyReportM2 />} />
      <Route path="/adm/rawdata" element={<ADMRawData />} />

      <Route
        path="/plant_architecture/machine_master"
        element={<MachineMaster />}
      />
    </Routes>
  );
}
