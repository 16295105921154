import React, { useEffect, useState } from "react";
import { Box, Grid, Paper, Typography } from "@mui/material";

import LineChart from "../Graphs/LineChart";
import BarChart from "../Graphs/BarChart";
import RadialBarChart from "../Graphs/RadialBarChart";
import RadialBarChart2 from "../Graphs/RadialChart2";
import { EMSDashboardData } from "../../api/EMS API/getdashboarddata";
import '../../assets/css/emsdashboard.css'
import { width } from "@mui/system";
import { Pix } from "@mui/icons-material";
export default function EMSDashboard() {
  const [dashboardData, setDashboardData] = useState([]);
  const [activeEnergy,setActiveEnergy]=useState([])

  useEffect(() => {
    fetchEnergyMeasurementsData();
    fetchDailyConsumptionData()
    const interval = setInterval(() => {
      fetchEnergyMeasurementsData();
      fetchDailyConsumptionData()
    }, 30000);
    return () => clearInterval(interval);
  }, []);

  const fetchEnergyMeasurementsData = async () => {
    try {
      const response = await EMSDashboardData.getEMSDasboardData();
      console.log("electrical measurement data:", response.data);
      setDashboardData(response.data);
    } catch (error) {
      console.error("Error fetching energy data:", error);
    }
  };
  const fetchDailyConsumptionData=async()=>{
    try {
      const response= await EMSDashboardData.getDailyConsumptionPerDay();
      console.log("daily consumption data:",response.data)
      setActiveEnergy(response.data)
    } catch (error) {
      console.error("error getting daily consumption data:",error)
    }
  }

  const getLatestReading = () => {
    return dashboardData.length > 0 ? dashboardData[0] : null;
  };

  const getLastReading = () => {
    return activeEnergy.length > 0 ? activeEnergy[activeEnergy.length - 1] : null;
  };
  const latestReading = getLatestReading();
  const lastReading=getLastReading();

  const boxData = [
    { heading: "Total Voltage", value: latestReading ? `${(parseFloat(latestReading.voltageR) + parseFloat(latestReading.voltageY) + parseFloat(latestReading.voltageB)).toFixed(2)} V` : "N/A", color: "grey.200", height: 100 },
    { heading: "Total Current", value: latestReading ? `${(parseFloat(latestReading.currentR) + parseFloat(latestReading.currentY) + parseFloat(latestReading.currentB)).toFixed(2)} A` : "N/A", color: "grey.300", height: 100 },
    { heading: "Total Energy Consumed", value: latestReading ? `${parseFloat(latestReading.activeEnergy).toFixed(2)} kWh` : "N/A", color: "grey.400", height: 100 },
    {heading: "Power Factor", value:latestReading ?`${parseFloat(latestReading.powerFactor).toFixed(2)} ` : "N/A", color: "grey.400", height: 100 },
    {heading: "Today's Consumption", value:lastReading ?`${parseFloat(lastReading.activeEnergy).toFixed(2)} kWh` : "N/A", color: "grey.400", height: 100 },

  ];

  const getChartData = (dataKey, limit = 5, useTime = false) => {
    return dashboardData.slice(0, limit).map(reading => ({
      name: reading.dateTime.split(' ')[1],
      value: parseFloat(reading[dataKey])
    })).reverse();
  };
  const prepareActiveEnergyData = () => {
    return activeEnergy.map(item => ({
      name: item.dateTime,
      value: parseFloat(item.activeEnergy)
    })).sort((a, b) => new Date(a.name) - new Date(b.name));
  };
  const chartData = [
    
  //  {title: "Demand %",
    //  chart: (<RadialBarChart percentage={latestReading ? (parseFloat(latestReading.activePower) / parseFloat(latestReading.demandActivePower) * 100).toFixed(2) : 0} color="#00acc1" />),
    //},
    //{
     // title: "Power Factor",
      //chart: <RadialBarChart2 
        //       value={latestReading && latestReading.powerFactor !== null && latestReading.powerFactor !== undefined 
          //            ? parseFloat(latestReading.powerFactor) 
            //          : 0} 
              // color="#00acc1" 
             ///>,
    //},   
    {
      // title: "Per Day Consumption(kWh)",
      chart: (
        <BarChart
          data={prepareActiveEnergyData().map(d => d.value)}
          labels={prepareActiveEnergyData().map(d => d.name)}
          title="Per Day Consumption(kWh)"
          xAxisLabel="Date"
          yAxisLabel="KWH"
        />
      ),
      md: 4
    },
    {
      title: "Voltage (V)",
      chart: (
        <LineChart
          data={[
            { name: "R", data: getChartData('voltageR', 5, true).map(d => d.value) },
            { name: "Y", data: getChartData('voltageY', 5, true).map(d => d.value) },
            { name: "B", data: getChartData('voltageB', 5, true).map(d => d.value) },
          ]}
          labels={getChartData('voltageR', 5, true).map(d => d.name)}
          title=" "
          xAxisLabel="Time"
          yAxisLabel="Voltage"
        />
      ),
      md: 4
    },
    {
      title: "Current (A)",
      chart: (
        <LineChart
          data={[
            { name: "R", data: getChartData('currentR', 5, true).map(d => d.value) },
            { name: "Y", data: getChartData('currentY', 5, true).map(d => d.value) },
            { name: "B", data: getChartData('currentB', 5, true).map(d => d.value) },
          ]}
          labels={getChartData('currentR', 5, true).map(d => d.name)}
          title=" "
          xAxisLabel="Time"
          yAxisLabel="Current"
        />
      ),
      md:4
    },
    {
      title: "Import vs Export",
      chart: (
        <>
          <LineChart
            data={[
              { name: "Import", data: getChartData('importEnergy', 5, true).map(d => d.value) },
              { name: "Export", data: getChartData('exportEnergy', 5, true).map(d => d.value) },
            ]}
            labels={getChartData('importEnergy', 5, true).map(d => d.name)}
            title=" "
            xAxisLabel="Time"
            yAxisLabel="Power"
          />
          <Typography
            sx={{
              color: 'white',
              fontWeight: 'bold',
              position: 'absolute',
              top: '10px',
              right: '10px',
              backgroundColor: 'rgba(0, 0, 0, 0.5)',
              padding: '5px 10px',
              borderRadius: '5px',
            }}
          >
            Loss: {latestReading ? parseFloat(latestReading.importVsExportEnergy).toFixed(2) : 'N/A'} kWh
          </Typography>
        </>
      ),
    },
    {
      title: "THD Current (%)",
      chart: (
        <LineChart
          data={[
            { name: "R", data: getChartData('currentThdR', 5, true).map(d => d.value) },
            { name: "Y", data: getChartData('currentThdY', 5, true).map(d => d.value) },
            { name: "B", data: getChartData('currentThdB', 5, true).map(d => d.value) },
          ]}
          labels={getChartData('currentThdR', 5, true).map(d => d.name)}
          title=" "
          xAxisLabel="Time" 
          yAxisLabel="THD %"
        />
      ),
    },
    {
      title: "THD Voltage (%)",
      chart: (
        <LineChart
          data={[
            { name: "R", data: getChartData('voltageThdR', 5, true).map(d => d.value) },
            { name: "Y", data: getChartData('voltageThdY', 5, true).map(d => d.value) },
            { name: "B", data: getChartData('voltageThdB', 5, true).map(d => d.value) },
          ]}
          labels={getChartData('voltageThdR', 5, true).map(d => d.name)}
          title=" "
          xAxisLabel="Time"
          yAxisLabel="THD %"
        />
      ),
    },
  ];

  return (
    <div style={{ padding: "20px" }}>
      <Grid container spacing={1}>
        {boxData.map((box, index) => (
          <Grid item xs={12} sm={6} md={2.4}  key={index} >
            <Paper elevation={3}>

              <Box height={box.height} display="flex" flexDirection="column"  >
                <Box
                  flex={1}
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                  bgcolor="white"
                >
                  <Typography
                    sx={{ color: "rgba(3, 3, 62, 0.9)", fontWeight: "bold" }}
                  >
                    {box.heading}
                  </Typography>
                </Box>
                <Box
                  flex={1}
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                  bgcolor="rgba(3, 3, 62, 0.9)"
                >
                  <Typography sx={{ color: "white", fontWeight: "bold" }}>
                    {box.value}
                  </Typography>
                </Box>
              </Box>
            </Paper>
          </Grid>
        ))}
        {chartData.map((data, index) => (
          <Grid item xs={12} sm={3} md={data.md || 4} key={index}>
            <Paper
              elevation={3}
              style={{ padding: 5, backgroundColor: "rgba(3, 3, 62, 0.9)", position: "relative" }}
            >
              <Box
                height={300}
                display="flex"
                flexDirection="column"
                justifyContent="center"
                alignItems="center"
              >
                <Typography
                  sx={{ color: "white", fontWeight: "bold", marginBottom: 0 }}
                >
                  {data.title}
                </Typography>
                <Box
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                  width="100%"
                  height="100%"
                >
                  {data.chart}
                </Box>
              </Box>
            </Paper>
          </Grid>
        ))}
      </Grid>
    </div>
  );
}